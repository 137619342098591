<script>
export default {
  name: 'ArrowIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
    <defs>
      <path id="plus-button-prefix-a" d="M0 22L22 22 22 0 0 0z" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <path
        fill="currentcolor"
        d="M5.759 10h-.01C5.335 10 5 10.446 5 11c0 .553.334 1 .749 1H16.25c.414 0 .749-.447.749-1 0-.554-.334-1-.749-1H5.76z"
      />
      <path
        fill="currentcolor"
        d="M12 5.759v-.01C12 5.335 11.553 5 11 5c-.553 0-1 .334-1 .749V16.25c0 .414.447.749 1 .749.553 0 1-.334 1-.749V5.76z"
      />
      <mask id="plus-button-prefix-b" fill="currentcolor">
        <use xlink:href="#plus-button-prefix-a" />
      </mask>
      <path
        fill="currentcolor"
        d="M1.572 20.429H20.43V1.57H1.572V20.43zM.786 0C.352 0 0 .35 0 .786v20.428c0 .434.352.786.786.786h20.428c.433 0 .786-.352.786-.786V.786C22 .352 21.648 0 21.214 0H.786z"
        mask="url(#plus-button-prefix-b)"
      />
    </g>
  </svg>
</template>
