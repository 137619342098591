<script>
export default {
  name: 'ModalGallery',

  props: {
    devise: { type: Object },
    gallery: {
      type: Array,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    largeKey: {
      type: String,
      default: null,
    },
    smallKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      activeImage: 0,
      thumbsTranslate: 0,
    }
  },

  watch: {
    activeImage(newVal) {
      const theThumb = this.$refs[`imageThumb${newVal}`]
      if (theThumb[0]) {
        const bodyRect = this.$refs.thumbsContainer.getBoundingClientRect()
        const elemRect = theThumb[0].getBoundingClientRect()
        const offset = elemRect.left - bodyRect.left
        const elemScrollLeft = theThumb[0].offsetLeft

        if (offset < 0 || offset > bodyRect.width) {
          this.$refs.thumbs.scrollTo({
            left: elemScrollLeft,
            behavior: 'smooth',
          })
        }
      }
    },
  },

  methods: {
    prev() {
      if (this.activeImage - 1 < 0)
        this.activeImage = this.gallery.length - 1
      else
        this.activeImage -= 1
    },

    next() {
      if (this.activeImage + 2 > this.gallery.length)
        this.activeImage = 0
      else
        this.activeImage += 1
    },
  },
}
</script>

<template>
  <Teleport v-if="show" to="#portal-modal">
    <GlueUiModal :darkmode="true" modal-classes="w-full mx-4" @close="$emit('close')">
      <div class="pointer-events-none">
        <div class="relative flex flex-col justify-between" style="height: 90vh">
          <div v-if="title" class="text-white headline-3 p-8">
            {{ title }}
          </div>
          <div class="mb-4 text-white" style="max-height: 65vh">
            <div
              v-for="(image, key) in gallery"
              :key="key"
              class="w-full flex flex-col justify-between"
            >
              <div v-if="activeImage === key">
                <div
                  class="w-full  h-[60vh] overflow-hidden flex justify-center items-center bg-center bg-contain bg-no-repeat relative"
                >
                  <template v-if="image.url">
                    <VoixMedia :field="image.url" class="w-full h-full object-contain" />
                  </template>
                  <template v-else>
                    <VoixMedia v-if="$voix.breakpoint.isGreaterThan('sm')" :field="image" breakpoint="lg" class="w-full h-full object-contain" />
                    <VoixMedia v-else :field="image" breakpoint="sm" class="w-full h-full object-contain" />
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div
            class="absolute z-20 inset-0 flex justify-between items-center px-8 pointer-events-none"
          >
            <div
              class="pointer-events-auto cursor-pointer rounded-full w-10 h-10 flex justify-center items-center transition-all duration-200 hover:bg-glueblue-600"
              style="
                height: 2.5rem;
                width: 2.5rem;
                box-shadow: 0 0 1px 0px white inset, 0 0 1px 1px white;
                margin-top: -1.25rem;
              "
              @click="prev"
            >
              <IconsArrow class="w-5 text-gray-100" />
            </div>
            <div
              class="pointer-events-auto cursor-pointer rounded-full w-10 h-10 flex justify-center items-center transition-all duration-200 hover:bg-glueblue-600"
              style="
                height: 2.5rem;
                width: 2.5rem;
                box-shadow: 0 0 1px 0px white inset, 0 0 1px 1px white;
                margin-top: -1.25rem;
              "
              @click="next"
            >
              <IconsArrow class="w-5 transform rotate-180 text-gray-100" />
            </div>
          </div>

          <div ref="thumbsContainer" class="container mx-auto select-none">
            <div
              ref="thumbs"
              class="hidden md:flex w-full pt-2 pb-4 overflow-x-scroll no-scrollbar max-w-full transform"
              style="max-height: 25vh"
            >
              <div
                v-for="(image, key) in gallery"
                :key="key"
                :ref="`imageThumb${key}`"
                class="mr-2 cursor-pointer pointer-events-auto flex-shrink-0 duration-200 transform border-2"
                :class="{
                  'border-white -translate-y-2': activeImage === key,
                  'border-transparent': activeImage !== key,
                }"
                @click="activeImage = key"
              >
                <div class="w-full text-white">
                  <VoixMedia v-if="image.url" :field="image.url" class="h-32" draggable="false" />
                  <VoixMedia v-else :field="image" breakpoint="default" class="h-32" draggable="false" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </GlueUiModal>
  </Teleport>
</template>

<style>
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
