<script>
export default {
  name: 'ArrowIcon',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 10">
    <g
      fill="none"
      fill-rule="evenodd"
      stroke="currentcolor"
      stroke-linecap="square"
    >
      <path
        d="M0 5L16.98 5M17.708 5.085L13.042.299"
        transform="matrix(-1 0 0 1 19 0)"
      />
      <path
        d="M17.708 9.701L13.042 4.915"
        transform="matrix(-1 0 0 1 19 0) matrix(1 0 0 -1 0 14.615)"
      />
    </g>
  </svg>
</template>
