<script>
export default {
  name: 'OpenTable',

  props: {
    small: {
      type: Boolean,
      default: false,
    },
    rid: {
      type: String,
      default: 'rid=1011787&rid=994927&rid=1014763&rid=1188106&rid=1012876&rid=1013119&rid=994924&rid=1291345',
    },
    type: {
      type: String,
      default: 'multi',
    },
  },

  data() {
    return {
      windowHeight: 1200,
    }
  },

  computed: {
    containerStyles() {
      if (!this.small) {
        return {
          height: '150px ',
        }
      }

      if (this.windowHeight < 860) {
        return {
          height: `${this.windowHeight - 300}px`,
          overflowY: 'scroll',
        }
      }

      return {}
    },
  },

  mounted() {
    if (window)
      this.windowHeight = window.innerHeight

    if (this.small) {
      // Vertical
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.onload = function () {
        // remote script has loaded
      }
      script.src
        = `//www.opentable.com/widget/reservation/loader?rid=${this.rid}&type=${this.type}&theme=tall&iframe=true&domain=com&lang=en-US&newtab=false&ot_source=Restaurant%20website&ot_campaign=RestaurantReservations`
      this.$el.appendChild(script)
    }
    else {
      // Horizontal
      const script2 = document.createElement('script')
      script2.type = 'text/javascript'
      script2.async = true
      script2.onload = function () {
        // remote script has loaded
      }
      script2.src
        = `//www.opentable.com/widget/reservation/loader?rid=${this.rid}&type=${this.type}&theme=wide&iframe=true&domain=com&lang=en-US&newtab=false&ot_source=Restaurant%20website&ot_campaign=RestaurantReservations`
      this.$el.appendChild(script2)
    }
  },
}
</script>

<template>
  <div :style="containerStyles">
    <div />
  </div>
</template>
