<script>
import { getCurrentBreakpoint } from '@voix/composables/useBreakpoints'

export default {
  name: 'BookingGuests',

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      breakpoint: 'default',
      showDropdown: false,
      scrollTop: 0,
      dropdown: null,
      blankRoom: {
        numAdults: 2,
        numSeniors: 0,
        numInfantsInSeats: 0,
        numInfantsInLaps: 0,
        children: 0,
        childAges: [],
        childPositions: [],
      },
    }
  },
  computed: {
    booking: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      },
    },
    totalAdults() {
      let adults = 0
      this.booking.rooms.forEach((room) => {
        adults += Number.parseInt(room.numAdults)
      })
      return adults
    },

    totalChildren() {
      let children = 0
      this.booking.rooms.forEach((room) => {
        children += Number.parseInt(room.children)
      })
      return children
    },

    optionsDown() {
      const styles = []

      if (this.dropdown) {
        const formBounds = this.dropdown.getBoundingClientRect()

        if (formBounds.right > window.innerWidth)
          styles.push('right-0')
        else
          styles.push('left-0')

        if (formBounds.bottom + 30 > window.innerHeight) {
          styles.push('bottom-0')
          styles.push('mb-12')
        }
        else {
          styles.push('top-0')
          styles.push('mt-12')
        }
      }

      return styles
    },

    popoverStyles() {
      const styles = {}
      if (this.breakpoint.name !== 'default' && this.breakpoint.name !== 'sm' && this.breakpoint.name !== 'md') {
        styles.minWidth = '600px'
        styles.transform = 'translateX(-300px)'
      }
      else {
        styles.maxWidth = '300px'
        styles.maxHeight = '200px'
        styles['overflow-y'] = 'scroll'
      }

      return styles
    },
  },

  mounted() {
    window.addEventListener('scroll', () => {
      this.scrollTop = document.documentElement.scrollTop
    })
    this.scrollTop = document.documentElement.scrollTop

    this.$nextTick(() => {
      this.dropdown = this.$refs.dropdown
    })

    this.breakpoint = getCurrentBreakpoint(window.innerWidth)
  },

  methods: {
    enableDropdown() {
      this.scrollTop = document.documentElement.scrollTop + 1
      this.showDropdown = true
    },

    toggleDropdown() {
      this.scrollTop = document.documentElement.scrollTop + 1
      this.showDropdown = !this.showDropdown
    },

    setNumberOfRooms(e) {
      const rooms = e.target.value
      const currentRooms = this.booking.rooms.length

      if (currentRooms < rooms) {
        const numberToAdd = rooms - currentRooms
        for (let i = 0; i < numberToAdd; i += 1)
          this.booking.rooms.push(Object.assign({}, this.blankRoom))
      }
      else {
        const numberToSplice = Math.abs(rooms - currentRooms)
        this.booking.rooms.splice(1, numberToSplice)
      }
    },
  },
}
</script>

<template>
  <div class="relative">
    <div
      class="
        w-full
        font-sans2
        px-4
        py-5
        text-xs
        uppercase
        cursor-pointer
        flex
        items-center
        relative
        truncate
      "
      :class="{
        'z-40 bg-gray-600 text-white': showDropdown,
        'z-0 bg-white text-gray-600': !showDropdown,
      }"
      @click="toggleDropdown"
    >
      Adult ({{ totalAdults }}) - Child ( {{ totalChildren }} ) - Room (
      {{ booking.rooms.length }} )
    </div>
    <div
      v-if="showDropdown"
      class="fixed inset-0 z-30 bg-black opacity-0"
      @click="showDropdown = false"
    />
    <div
      ref="dropdown"
      class="
        absolute
        z-50
        bg-white
        text-gray-800
        font-sans2
        uppercase
        shadow-2xl
      "
      :class="optionsDown"
      :style="popoverStyles"
    >
      <div v-show="showDropdown">
        <div
          class="flex px-3 justify-between border-b border-gray-200 py-2"
        >
          <label class="text-base tracking-wider inline p-4">
            Number of Rooms
          </label>
          <div class="flex items-center relative text-center w-20">
            <select
              class="
                appearance-none
                absolute
                inset-0
                w-full
                px-4
                text-sm text-gray-700
                bg-gray-200
                rounded
              "
              @change="setNumberOfRooms"
            >
              <option :value="1">
                1 Room
              </option>
              <option :value="2">
                2 Rooms
              </option>
              <option :value="3">
                3 Rooms
              </option>
              <option :value="4">
                4 Rooms
              </option>
              <option :value="5">
                5 Rooms
              </option>
            </select>
            <div
              class="
                text-white-100
                opacity-0
                pl-2
                pointer-events-none
                absolute
                right-0
                mr-2
              "
            >
              <Icon class="w-8 h-8" name="heroicons:chevron-down-20-solid" />
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-6 px-6">
          <div
            v-for="(room, n) in booking.rooms.length"
            :key="n"
            class="relative py-4 border-b border-gray-300"
          >
            <div class="font-sans mb-2">
              Room {{ n + 1 }}
            </div>

            <div class="grid grid-cols-2 gap-4">
              <div class="glue-form">
                <label class="text-xs block mb-1 text-gray-600">Adults (18+)</label>
                <select
                  v-model="booking.rooms[n].numAdults"
                  class="w-full py-3 px-3 bg-gray-100 rounded"
                >
                  <option :value="1">
                    1
                  </option>
                  <option :value="2">
                    2
                  </option>
                  <option :value="3">
                    3
                  </option>
                  <option :value="4">
                    4
                  </option>
                  <option :value="5">
                    5
                  </option>
                  <option :value="6">
                    6
                  </option>
                  <option :value="7">
                    7
                  </option>
                  <option :value="8">
                    8
                  </option>
                </select>
              </div>

              <div class="glue-form">
                <label class="text-xs block mb-1 text-gray-600">Children</label>
                <select
                  v-model="booking.rooms[n].children"
                  class="w-full py-3 px-3 bg-gray-100 rounded"
                  style="box-sizing: border-box"
                >
                  <option :value="0">
                    0
                  </option>
                  <option :value="1">
                    1
                  </option>
                  <option :value="2">
                    2
                  </option>
                  <option :value="3">
                    3
                  </option>
                  <option :value="4">
                    4
                  </option>
                  <option :value="5">
                    5
                  </option>
                  <option :value="6">
                    6
                  </option>
                </select>
              </div>
            </div>

            <div
              v-show="booking.rooms[n].children > 0"
              id="child-ages-container"
              class="grid grid-cols-2 lg:grid-cols-3 gap-4 bg-white pt-4"
            >
              <template v-for="childNumber in Number.parseInt(booking.rooms[n].children)" :key="childNumber">
                <div>
                  <div>
                    <label class="block text-2xs">Child Age</label>
                    <select
                      v-model="booking.rooms[n].childAges[childNumber - 1]"
                      placeholder="Child Age"
                      class="py-2 px-3 bg-gray-100 rounded appearance-none text-xs"
                      :class="{
                        'border-red-500 border-2':
                          !booking.rooms[n].childAges[childNumber - 1],
                      }"
                    >
                      <option :value="undefined">
                        Child Age
                      </option>
                      <option :value="1">
                        ≤ 1
                      </option>
                      <option :value="2">
                        2
                      </option>
                      <option :value="3">
                        3
                      </option>
                      <option :value="4">
                        4
                      </option>
                      <option :value="5">
                        5
                      </option>
                      <option :value="6">
                        6
                      </option>
                      <option :value="7">
                        7
                      </option>
                      <option :value="8">
                        8
                      </option>
                      <option :value="9">
                        9
                      </option>
                      <option :value="10">
                        10
                      </option>
                      <option :value="11">
                        11
                      </option>
                      <option :value="12">
                        12
                      </option>
                      <option :value="13">
                        13
                      </option>
                      <option :value="14">
                        14
                      </option>
                      <option :value="15">
                        15
                      </option>
                      <option :value="16">
                        16
                      </option>
                      <option :value="17">
                        17
                      </option>
                    </select>
                  </div>
                  <template v-if="booking.type === 'flightAndHotel'">
                    <div
                      v-if="
                        !booking.rooms[n].childAges[childNumber - 1]
                          || booking.rooms[n].childAges[childNumber - 1] < 2
                      "
                      class="glue-form pt-2"
                    >
                      <div class="flex mb-2">
                        <input
                          v-model="
                            booking.rooms[n].childPositions[childNumber - 1]
                          "
                          type="radio"
                          value="inlap"
                          class="mr-2"
                        >
                        <label class="text-xs">In Lap</label>
                      </div>
                    </div>
                    <div
                      v-if="
                        !booking.rooms[n].childAges[childNumber - 1]
                          || booking.rooms[n].childAges[childNumber - 1] < 2
                      "
                      class="glue-form"
                    >
                      <div class="flex">
                        <input
                          v-model="
                            booking.rooms[n].childPositions[childNumber - 1]
                          "
                          type="radio"
                          value="inseat"
                          class="mr-2"
                        >
                        <label class="text-xs">In Seat</label>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
