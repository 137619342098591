<script>
import dayjs from 'dayjs'
import { DatePicker } from 'v-calendar'
import { getCurrentBreakpoint } from '@voix/composables/useBreakpoints'

import 'v-calendar/style.css'

export default {
  name: 'BookingCalendar',

  components: {
    DatePicker,
  },

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    placement: {
      type: String,
      default: 'bottom-end',
    },
    alerts: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      calendarContainer: null,
      showing: false,
      breakpoint: 1200,
    }
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      },
    },

    minDate() {
      if (this.modelValue.tracking.book_limit_min_date !== null) {
        const minDate = dayjs(this.modelValue.tracking.book_limit_min_date)
        if (minDate.isBefore(dayjs(), 'day'))
          return dayjs().toDate()

        return dayjs(this.modelValue.tracking.book_limit_min_date).toDate()
      }

      return dayjs().toDate()
    },

    maxDate() {
      if (this.modelValue.type === 'flightAndHotel')
        return dayjs().add(540, 'days').toDate()

      if (this.modelValue.tracking.book_limit_max_date && this.modelValue.tracking.book_limit_max_date !== null)
        return dayjs(this.modelValue.tracking.book_limit_max_date).toDate()

      return dayjs().add(540, 'days').toDate()
    },

    formattedDateRange() {
      if (
        this.modelValue.range
        && this.modelValue.range.start
        && !dayjs(this.modelValue.range.start).isSame(dayjs().add(1, 'day'), 'day')
        && !dayjs(this.modelValue.range.start).isSame(dayjs(), 'day')
      ) {
        return `${dayjs(this.modelValue.range.start).format('MMM D')} - ${dayjs(
          this.modelValue.range.end,
        ).format('MMM D')}`
      }
      return 'Check In - Check Out'
    },

    thePlacement() {
      let direction = `${this.placement}-start`
      if (this.calendarContainer) {
        const containerRect = this.calendarContainer.getBoundingClientRect()
        if (containerRect.left > window.innerWidth / 2)
          direction = `${this.placement}-end`
      }

      return direction
    },

    disabledDates() {
      const disabledDates = []
      this.alerts.map((a) => {
        if (a.type === 'sold_out') {
          disabledDates.push({
            start: dayjs(a.start_date).add(1, 'day').format('YYYY-MM-DD'),
            end: a.end_date,
          })
        }
        return a
      })

      return disabledDates
    },
    inputLineHeight() {
      if (
        this.breakpoint === 'default'
        || this.breakpoint === 'sm'
        || this.breakpoint === 'md'
      )
        return '1.5'

      return 1
    },

    areRestrictionsOnCalendar() {
      return this.alerts.filter(a => a.type === 'restriction').length
    },

    areBestRatesOnCalendar() {
      return this.alerts.filter(a => a.type === 'best_rates').length
    },

    areSoldOutOnCalendar() {
      return this.alerts.filter(a => a.type === 'sold_out').length
    },

    areDealsOnCalendar() {
      return this.alerts.filter(a => a.type === 'deals').length
    },

    numberOfDays() {
      if (this.modelValue && this.modelValue.range) {
        return (
          dayjs(this.modelValue.range.end).diff(
            dayjs(this.modelValue.range.start),
            'days',
          ) + 1
        )
      }
      return 0
    },

    numberOfNights() {
      return dayjs(this.modelValue.range.end).diff(
        dayjs(this.modelValue.range.start),
        'days',
      )
    },

    alertAttributes() {
      const alerts = []

      this.alerts.forEach((alert) => {
        // const popover = {
        //   label: alert.type === 'sold_out' ? 'Sold Out' : alert.type,
        // };

        if (alert.type === 'sold_out') {
          alerts.push({
            highlight: {
              color: 'red',
              fillMode: 'solid',
            },
            dates: {
              start: dayjs(alert.start_date).toDate(),
              end: dayjs(alert.end_date).toDate(),
            },
            popover: {
              label: 'Sold Out',
              hideIndicator: true,
              isInteractive: false,
            },
            customData: alert,
          })
        }
        else if (alert.type === 'restriction') {
          alerts.push({
            highlight: {
              color: 'orange',
              fillMode: 'solid',
            },
            dates: {
              start: dayjs(alert.start_date).toDate(),
              end: dayjs(alert.end_date).toDate(),
            },
            popover: {
              label: 'Restriction',
              hideIndicator: true,
              isInteractive: false,
            },
            customData: alert,
          })
        }
        else {
          alerts.push({
            highlight: {
              color: 'green',
              fillMode: 'solid',
            },
            popover: {
              label: 'Best Rates',
              hideIndicator: true,
            },
            dates: {
              start: dayjs(alert.start_date).toDate(),
              end: dayjs(alert.end_date).toDate(),
            },
            customData: alert,
          })
        }
      })

      return alerts
    },

    numberOfColumns() {
      if (this.breakpoint === 'lg' || this.breakpoint === 'xl' || this.breakpoint === '2xl')
        return 2

      return 1
    },
  },
  mounted() {
    this.breakpoint = getCurrentBreakpoint(window.innerWidth).name

    this.$nextTick(() => {
      this.calendarContainer = this.$refs.calendarContainer
    })
  },
}
</script>

<template>
  <div
    ref="calendarContainer"
    class="relative flex items-stretch"
  >
    <div v-if="showing" class="fixed inset-0 z-30 bg-black opacity-0" />
    <div id="booking-calendar" class="flex w-full cursor-pointer">
      <DatePicker

        v-model.range="localValue.range"
        mode="date"
        popover-direction="bottom"
        :popover-keep-visible-on-input="true"
        popover-align="center"
        popover-visibility="focus"
        :columns="numberOfColumns"
        :min-date="minDate"
        :max-date="maxDate"
        :disabled-dates="disabledDates"
        :attributes="alertAttributes"
        :popover="{ placement: thePlacement, visibility: 'focus' }"
        @popover-did-appear="showing = true"
        @popover-did-hide="showing = false"
      >
        <template #default="slotProps">
          <div class="relative flex w-full cursor-pointer" @click.prevent="slotProps.togglePopover">
            <input
              type="text"
              :value="formattedDateRange"
              placeholder="Check In - Check Out"
              class="w-full font-sans px-4 py-5 text-xs uppercase outline-none cursor-pointer"
              :class="{
                'bg-white text-gray-600 ': !showing,
                'bg-gray-600 text-white': showing,
              }"
              style="line-height: 1"
              :style="{ 'line-height': inputLineHeight }"
              readonly="true"
            >
            <div
              v-if="numberOfDays > 1"
              class="absolute right-0 top-0 bottom-0 opacity-50 flex items-center space-x-2 font-sans2 text-2xs pr-2"
            >
              <div class="flex flex-col items-center space-y-2">
                <svg
                  class="w-5 h-5 text-blue-300"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"
                  />
                </svg>
                {{ numberOfNights }} Nights
              </div>
              <div class="flex flex-col items-center space-y-2">
                <svg
                  class="w-5 h-5 text-yellow-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                    clip-rule="evenodd"
                  />
                </svg>
                {{ numberOfDays }} Days
              </div>
            </div>
          </div>
        </template>
        <template #day-popover="{ attributes }">
          <div class="relative isolate z-[500] text-red-500">
            <div v-for="(attribute, key) in attributes" :key="key">
              {{ attribute.customData.tooltip_text }}
            </div>
          </div>
        </template>
        <template #footer>
          <div
            class="booking-calendar-footer pt-4 px-4 flex justify-between items-end normal-case text-xs"
          >
            <div class="flex flex-wrap max-w-[250px]">
              <div
                v-if="areRestrictionsOnCalendar"
                class="flex items-center mr-4"
              >
                <div
                  class="w-4 h-4 mr-2"
                  style="background-color: #ffbd52"
                />
                <span class="opacity-50">Restrictions</span>
              </div>
              <div v-if="areBestRatesOnCalendar" class="flex items-center mr-4">
                <div
                  class="w-4 h-4 mr-2"
                  style="background-color: #c0ec8f"
                />
                <span class="opacity-50"> Best Rates</span>
              </div>
              <div v-if="areSoldOutOnCalendar" class="flex items-center mr-4">
                <div
                  class="w-4 h-4 mr-2"
                  style="background-color: #fca5a5"
                />
                <span class="opacity-50"> Sold Out</span>
              </div>
              <div v-if="areDealsOnCalendar" class="flex items-center mr-4">
                <div
                  class="w-4 h-4 mr-2"
                  style="background-color: #c0ec8f"
                />
                <span class="opacity-50"> Hot Dates</span>
              </div>
              <div class="flex items-center">
                <div
                  class="w-4 h-4 mr-2"
                  style="background-color: #dde5fe"
                />
                <span class="opacity-50"> Current Selection</span>
              </div>
            </div>
            <div v-if="numberOfDays > 1" class="opacity-50 flex space-x-2">
              <div class="flex flex-col items-center space-y-2">
                <svg
                  class="w-6 h-6 text-blue-300"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"
                  />
                </svg>
                {{ numberOfNights }} Nights
              </div>
              <div class="flex flex-col items-center space-y-2">
                <svg
                  class="w-6 h-6 text-yellow-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                    clip-rule="evenodd"
                  />
                </svg>
                {{ numberOfDays }} Days
              </div>
            </div>
          </div>
        </template>
        <!-- <template v-slot:day-content="{ day, dayEvents }" >
             <div v-on="dayEvents">
              <div class="font-sans2 font-bold flex justify-center">
                {{ day.label }}
              </div>
              <div>$129</div>
            </div>
          </template> -->
      </DatePicker>
    </div>
  </div>
</template>

<style lang="scss">
#booking-calendar {
  @apply relative font-sans z-40;

  .vc-popover-content-wrapper {
    @apply bottom-0 font-sans2 text-sm;
  }

  .vc-container {
    @apply relative z-40;
  }

  .vc-title {
    @apply text-gray-600 text-sm uppercase font-normal;
  }

  .vc-weekday {
    @apply text-xs text-glueblue-600
  }

  .vc-day {
    @apply px-1 py-1
  }

  .booking-calendar-footer {
    @apply font-sans2 font-bold;
  }

  .vc-blue {
    .vc-highlight-bg-solid {
      @apply bg-blue-300;
    }

    .vc-highlight-bg-outline {
      @apply border-blue-200;
    }
  }

  .vc-green {
    @apply text-zinc-600;
    .vc-highlight-bg-solid {
      background-color: rgb(192, 236, 143);
    }
  }

  .vc-orange {
    .vc-highlight-bg-solid {
      background-color: rgb(255, 189, 82);
    }
  }

  .vc-red {
    @apply text-white;
    .vc-highlight-bg-solid {
      @apply bg-red-300;
    }
  }
}
</style>
