<script>
export default {
  name: 'AirplaneIcon',
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 19">
    <path
      fill="currentcolor"
      d="M20.612 10c-.518.5-1.553 1-3.106 1-.445 0-1.998-.014-3.984-.033l-4.555 7.6c-.143.24-.491.433-.777.433H7.155c-.286 0-.459-.216-.386-.483l2.07-7.598-5.165-.055-2.107 3.214c-.154.233-.51.422-.795.422H.426c-.285 0-.472-.22-.415-.49l.83-3.989c.057-.27.058-.709.002-.98L.01 4.99c-.056-.27.13-.49.416-.49h.346c.285 0 .641.189.794.423l2.107 3.214 5.166-.056L6.769.484C6.696.217 6.87 0 7.155 0H8.19c.286 0 .634.194.777.432l4.555 7.601c1.986-.02 3.54-.033 3.984-.033 1.553 0 2.588.5 3.106 1 .517.5.517.5 0 1zm-1.386-.666C18.758 9.166 18.34 9 16.939 9c-.422 0-1.948.005-3.87.012L8.19 1h-.518l2.443 8.023-7.333.029L.944 5.5l1.035 4-1.035 4 1.838-3.552 7.333.029L7.672 18h.518l4.879-8.012c1.922.007 3.448.012 3.87.012 1.402 0 1.82-.166 2.287-.334.468-.166.468-.166 0-.332z"
    />
  </svg>
</template>
