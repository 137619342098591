<script>
export default {
  name: 'BuildingIcon',
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 15">
    <g fill="currentcolor" fill-rule="evenodd">
      <path
        d="M13.872 14.08h4.149V4.942h-4.149v9.136zm-.979 0h-1.132v-2.64c0-.253-.22-.46-.49-.46H7.736c-.27 0-.488.207-.488.46v2.64H6.106V.92h6.787v13.16zm-4.667 0h2.557V11.9H8.226v2.18zm-7.247 0h4.149V4.942H.979v9.136zM13.872 4.022V.46c0-.253-.219-.46-.49-.46H5.617c-.27 0-.488.207-.488.46v3.563H.489c-.27 0-.489.207-.489.46V14.54c0 .253.219.46.49.46h18.02c.27 0 .49-.207.49-.46V4.484c0-.254-.22-.46-.49-.46h-4.638z"
      />
      <path
        d="M7.5 5L8.5 5 8.5 2 7.5 2zM10.5 5L11.5 5 11.5 2 10.5 2zM7.5 9L8.5 9 8.5 6 7.5 6zM10.5 9L11.5 9 11.5 6 10.5 6zM2.5 9L3.5 9 3.5 6 2.5 6zM2.5 13L3.5 13 3.5 10 2.5 10zM15.5 9L16.5 9 16.5 6 15.5 6zM15.5 13L16.5 13 16.5 10 15.5 10z"
      />
    </g>
  </svg>
</template>
