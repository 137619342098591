<script setup lang="ts">
import { computed, defineEmits, ref } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import type { ModelRef } from 'vue'

const props = defineProps<{
  modelValue: ModelRef<string>
}>()

const emit = defineEmits(['setAirport'])

const airportLookup = ref('')
const airports: Ref<Array<{ faa_code: string, name: string }>> = ref([])

const lookupPlaceholder = computed(() => {
  if (props.modelValue)
    return props.modelValue

  return 'Airport Code'
})

async function lookupAirport() {
  if (airportLookup.value.length > 1) {
    const { data } = await useFetch('/api/airports', {
      method: 'POST',
      body: JSON.stringify({ query: airportLookup.value }),
    })
    airports.value = data.value
  }
}

const lookupAirportDebounce = useDebounceFn(() => {
  lookupAirport()
}, 1000)

function selectAirport(faaCode: string) {
  emit('setAirport', faaCode)
  airports.value = {}
  airportLookup.value = ''
}

function clearAirport() {
  emit('setAirport', '')
  airports.value = {}
  airportLookup.value = ''
}
</script>

<template>
  <div class="flex">
    <div class="flex w-full">
      <input
        v-model="airportLookup"
        type="text"
        :placeholder="lookupPlaceholder"
        class="
          w-full
          bg-white
          text-gray-600
          font-sans2
          px-4
          py-5
          text-xs
          uppercase
          placeholder-gray-600
          outline-none
        "
        @keyup="lookupAirportDebounce"
        @focus="clearAirport"
      >
    </div>
    <ul
      v-if="airports && Object.keys(airports).length > 0"
      class="absolute mt-12 bg-white shadow z-10"
    >
      <li
        v-for="(airport, faaCode) in airports"
        :key="faaCode"
        class="
          p-3
          px-4
          border-b border-gray-200
          text-gray-600 text-xs text-left
          cursor-pointer
          font-sans2
          hover:bg-glueblue-700 hover:text-white
        "
        @click="selectAirport(airport.faa_code)"
      >
        {{ airport.name }} ({{ airport.faa_code }})
      </li>
    </ul>
  </div>
</template>
