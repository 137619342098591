<script>
export default {
  name: 'GlueModal',

  props: {
    devise: { type: Object },
    modalClasses: {
      type: String,
      default: 'w-full md:w-3/4 h-full  py-10 px-4 md:p-10',
    },
    darkmode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    finalModalClass() {
      let classes = this.modalClasses
      if (!this.darkmode)
        classes += ' bg-white'

      return classes
    },
  },
  mounted() {
    const body = document.body
    body.classList.add('overflow-hidden')
  },
  unmounted() {
    const body = document.body
    body.classList.remove('overflow-hidden')
  },
}
</script>

<template>
  <div class="fixed z-50 inset-0 flex justify-center items-center">
    <div class="absolute inset-0 z-40 bg-black/75" @click="$emit('close')" />
    <div
      class="absolute top-0 right-0 z-50 mt-4 mr-8 cursor-pointer text-white"
      @click="$emit('close')"
    >
      <svg
        class="w-8 h-8"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
    <div
      class="relative z-50 py-12 flex justify-center max-h-screen w-full overflow-y-scroll pointer-events-none"
    >
      <div
        class="relative z-50 overflow-y-scroll md:overflow-y-visible pointer-events-auto"
        :class="finalModalClass"
      >
        <slot />
      </div>
    </div>
  </div>
</template>
