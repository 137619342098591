import { usePageStore } from '@voix/store/pageStore'
import { v4 as uuidv4 } from 'uuid'

export function useAtlantisLink() {
  const pageStore = usePageStore()

  function onLinkCLick(url: string) {
    // handle switchfly clicks
    const page = pageStore.currentPage
    if (url.includes('secure.atlantisbahamas.com') && page.site_id === 1) {
      const params = url.split('?')
      if (params.length > 1) {
        const bookingSubmittionCookie = useCookie('booking-submission-id')
        const bookingSubmittionFrom = useCookie('booking-submitted-from')
        bookingSubmittionCookie.value = uuidv4()

        const from = window.location.href
        bookingSubmittionFrom.value = from

        $voixFetch(`/user-booking-interstitial?${params[1]}&submission_id=${bookingSubmittionCookie.value}&from=${from}`)
      }
    }
  }

  return { onLinkCLick }
}
