<script setup lang="ts">
export interface Props {
  openOn?: string
  label?: string
  closeOnSelect?: boolean
  matchWidth?: boolean
  buttonClasses?: string
  dropdownClasses?: string
  dropdownStyles?: any
  dim?: boolean
}

withDefaults(defineProps<Props>(), {
  openOn: 'click',
  label: 'Select',
  closeOnSelect: true,
  matchWidth: false,
  buttonClasses: 'w-full',
  dropdownClasses: 'w-full',
  dropdownStyles: {},
  dim: false,
})

const button: Ref<HTMLElement | null> = ref(null)
const dropdown: Ref<HTMLElement | null> = ref(null)
const showDropdown = ref(false)
const buttonPosition = ref({ top: 0, left: 0, right: 0, bottom: 0, width: 0, height: 0 })
const dropdownPosition = ref({ top: 0, left: 0, right: 0, bottom: 0, width: 0, height: 0 })

function toggleDropdown() {
  if (button.value)
    buttonPosition.value = button.value.getBoundingClientRect()

  showDropdown.value = !showDropdown.value
}

const dropdownPositionStyles = reactive({ top: 'auto', left: 'auto', right: 'auto', bottom: 'auto' })

watchEffect(() => {
  if (showDropdown.value)
    setDropdownPositionStyles()
})

function setDropdownPositionStyles() {
  if (window && dropdown.value) {
    dropdownPosition.value = dropdown.value.getBoundingClientRect()

    const idealTop = buttonPosition.value.top + buttonPosition.value.height
    const idealLeft = buttonPosition.value.left
    const idealRight = window.innerWidth - buttonPosition.value.right
    const idealBottom = window.innerHeight - buttonPosition.value.bottom + buttonPosition.value.height

    dropdownPositionStyles.top = idealTop + dropdownPosition.value.height > window.innerHeight ? `auto` : `${idealTop}px`
    dropdownPositionStyles.bottom = idealTop + dropdownPosition.value.height > window.innerHeight ? `${idealBottom}px` : `auto`
    dropdownPositionStyles.left = idealLeft + dropdownPosition.value.width > window.innerWidth ? `auto` : `${idealLeft}px`
    dropdownPositionStyles.right = idealLeft + dropdownPosition.value.width > window.innerWidth ? `${idealRight}px` : `auto`
  }
}
</script>

<template>
  <div class="group relative" :class="{ 'show-dropdown': showDropdown }">
    <div class="relative w-full flex justify-center items-center" @click="toggleDropdown">
      <button ref="button" :class="buttonClasses" v-html="label" />

      <div class="absolute top-0 bottom-0 right-2 flex items-center">
        <Icon
          name="heroicons:chevron-down-20-solid" class="pointer-events-none duration-200 w-5 h-5" :class="{
            'rotate-180': showDropdown,
          }"
        />
      </div>
    </div>

    <Teleport v-if="showDropdown" to="#portal-modal">
      <div class="fixed z-40 inset-0 " @click="toggleDropdown">
        <div class="absolute inset-0" :style="dropdownPositionStyles">
          <div ref="dropdown" :class="dropdownClasses" :style="dropdownStyles">
            <slot />
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>
