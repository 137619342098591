<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  error: {
    statusCode: number
    message: string
    stack: string
  }
}>()

const statusCode = computed(() => {
  if (props.error?.statusCode)
    return props.error?.statusCode

  return 500
})

const route = useRoute()

if (statusCode.value !== 404 || process.env.NODE_ENV === 'development') {
  // await useAsyncData(
  //   'errorWebhook',
  //   () => $fetch('/api/error-webhook', {
  //     method: 'POST',
  //     body: {
  //       url: route.fullPath,
  //       error: props.error,
  //     },
  //   }),
  // )
}
</script>

<template>
  <NuxtLayout name="atlantisbahamas">
    <ErrorsCatchAll :error="error" />
  </NuxtLayout>
</template>
