<script>
import { usePageStore } from '@voix/store/pageStore'

export default {

  props: {
    disableable: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    special: {
      type: Number,
      default: null,
    },
    track: {
      type: String,
      default: null,
    },
    location: {
      type: String,
      default: 'generic',
    },
    origin: {
      type: String,
      default: 'http://AtlantisBahamas.com/',
    },
    cobrand: {
      type: String,
      default: null,
    },

  },

  data() {
    return {
      showBookNow: false,
      theCobrand: null,
    }
  },

  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
  },

  mounted() {
    if (this.location === 'navigation') {
      document.addEventListener(
        'click',
        (event) => {
          // If the clicked element doesn't have the right selector, bail
          if (!event.target.matches('.book-now'))
            return

          // Don't follow the link
          event.preventDefault()

          // Log the clicked element in the console
          this.showBookNow = true
        },
        false,
      )

      const urlParams = new URLSearchParams(window.location.search)
      const errorMessage = urlParams.get('ERROR_MESSAGE')

      if (errorMessage)
        this.showBookNow = true
    }

    if (this.cobrand)
      this.theCobrand = this.cobrand
  },
  methods: {
    toggleBookingForm() {
      this.showBookNow = !this.showBookNow
    },
    closeBookingFormModal() {
      this.showBookNow = false
    },
  },
}
</script>

<template>
  <div v-if="currentPage?.site_id !== 2">
    <div class="relative">
      <div
        v-if="showBookNow"
        class="fixed inset-0 z-50 bg-black opacity-0"
        @click="showBookNow = false"
      />

      <div class="relative" @click="showBookNow = true">
        <slot :active="showBookNow" />
      </div>

      <template v-if="dropdown && showBookNow">
        <BookingForm
          :inline="inline"
          :dropdown="dropdown"
          :horizontal="false"
          :special="special"
          :location="location"
          :origin="origin"
          :cobrand="theCobrand"
          class="z-50"
        />
      </template>

      <Teleport v-if="showBookNow && !dropdown" to="#portal-modal">
        <div
          class="fixed z-40 inset-0"
        >
          <div class="absolute z-30 inset-0 bg-black/75" @click="closeBookingFormModal" />
          <div class="absolute z-40 inset-0 flex justify-center items-center pointer-events-none">
            <div class="relative bg-white w-full max-w-5xl mx-8 text-center p-8 pointer-events-auto">
              <button class="absolute z-10 top-0 right-0 -translate-y-8 md:translate-x-8" @click="closeBookingFormModal">
                <Icon class="w-8 h-8 text-white" name="heroicons:x-mark-20-solid" />
              </button>
              <div class="flex items-center">
                <BookingForm
                  :inline="inline"
                  :horizontal="false"
                  :special="special"
                  :location="location"
                  :origin="origin"
                  :cobrand="theCobrand"
                  class="w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </Teleport>
    </div>
  </div>
</template>
