import { useSiteUtils } from '@voix/composables/useSiteUtils'

export default defineNuxtRouteMiddleware(async (to) => {
  const { getCurrentDomain } = useSiteUtils()
  const domain = getCurrentDomain()

  if (
    to.path === '/travelagent/dashboard'
    && domain === 'www.atlantisbahamas.com'
  ) {
    const travelAgent = useCookie('atlantis-travel-agent', { maxAge: 604800 })

    let allowed = false

    if (travelAgent.value)
      allowed = true

    if (
      to.query?.iata_num
    ) {
      try {
        const response = await $voixFetch(`/api/atlantis/travel-agents/${to.query.iata_num}`)
        travelAgent.value = JSON.stringify(response)
        allowed = true
      }
      catch (error) {
        console.log('Error checking IATA number...', error)
      }
    }

    if (!allowed)
      return navigateTo('/travelagent?error=invalid_iata_num')
  }
})
