<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { usePageField } from '@voix/composables/usePageField'

const showBookNow = ref(false)

function closeBookingFormModal() {
  showBookNow.value = false
}

const route = useRoute()
const router = useRouter()
watch(route, (to) => {
  if (to.hash === '#book-now' || to.hash === '#booknow') {
    showBookNow.value = true
    router.push({ hash: '#book-now-clicked' })
  }
}, { deep: true })

// Check if the page has a setting to alert when
// external links are clicked and popup a warning.
const pageField = usePageField()
const warnOnExternal = pageField.get('warnOnExternal', false)
const showExternalWarning = ref(false)
const externalDestination = ref('')
const warnOnExternalText = pageField.get('warnOnExternalText', '<p>You are leaving our site and we cannot be held responsible for the content of external websites.</p>')

function warnOnExternalListener(event: any) {
  const target = event.target as HTMLAnchorElement
  if (target.tagName === 'A' && target.hostname !== window.location.hostname && !showExternalWarning.value) {
    showExternalWarning.value = true
    externalDestination.value = target.href
    event.preventDefault()
  }
}

onMounted(() => {
  if (warnOnExternal.value)
    document.addEventListener('click', warnOnExternalListener)
})

onUnmounted(() => {
  if (warnOnExternalListener)
    document.removeEventListener('click', warnOnExternalListener)
})
</script>

<template>
  <NuxtPage />
  <div>
    <Teleport to="#portal-modal">
      <div
        v-if="showBookNow"
        class="fixed z-40 inset-0"
      >
        <div class="absolute z-30 inset-0 bg-black/75" @click="closeBookingFormModal" />
        <div class="absolute z-40 inset-0 flex justify-center items-center pointer-events-none">
          <div class="relative bg-white w-full max-w-5xl mx-8 text-center p-8 pointer-events-auto">
            <button class="absolute z-10 top-0 right-0 -translate-y-8 md:translate-x-8" @click="closeBookingFormModal">
              <Icon class="w-8 h-8 text-white" name="heroicons:x-mark-20-solid" />
            </button>
            <div class="flex items-center">
              <BookingForm
                :horizontal="false"
                class="w-full"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-if="showExternalWarning" class="fixed z-40 inset-0">
        <div class="absolute z-30 inset-0 bg-black/75" @click="showExternalWarning = false" />
        <div class="absolute z-40 inset-0 flex justify-center items-center pointer-events-none">
          <div class="relative bg-white w-full max-w-lg mx-8 text-center p-8 pointer-events-auto border-t-8 border-red-500">
            <button class="absolute z-10 top-0 right-0 -translate-y-8 md:translate-x-8" @click="showExternalWarning = false">
              <Icon class="w-8 h-8 text-white" name="heroicons:x-mark-20-solid" />
            </button>
            <div class="flex flex-col items-center">
              <div class="prose">
                <h3>You are leaving our website</h3>
                <VoixWysiwyg :field="warnOnExternalText" />
              </div>

              <div class="flex space-x-2 mt-8">
                <button class="glue-btn bg-glueblue-600 text-white uppercase body-11 tracking-widest py-3 px-6 font-bold w-full" @click="showExternalWarning = false">
                  Cancel
                </button>
                <a
                  class="glue-btn btn-ghost border-zinc-600 text-zinc-700 uppercase body-11 tracking-widest py-3 px-6 font-bold w-full"
                  :href="externalDestination"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Continue
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<style scoped>
.page-enter-active,
.page-leave-active {
  transition: all 0.3s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
