import { useSiteUtils } from '@voix/composables/useSiteUtils'
import { useVoixUserAuth } from '@voix/composables/useVoixUserAuth'
import { useUserStore } from '@voix/store/userStore'
import { getCurrentUser } from '@voix/composables/queries/useQueryCurrentUser'

export default defineNuxtRouteMiddleware(async (to) => {
  const { getCurrentDomain } = useSiteUtils()
  const domain = getCurrentDomain()

  const domains = [
    'reefownersatlantis.com',
    'reef.lbm.co',
  ]

  const reefAuthState = useCookie('reef-auth-state')

  if (reefAuthState.value !== 'authenticated' && reefAuthState.value !== 'guest')
    reefAuthState.value = 'guest'

  if (domains.includes(domain) && !to.path.includes('/voix')) {
    let allowed = false

    const userStore = useUserStore()
    await getCurrentUser()
    const currentUser = userStore.currentUser

    if (currentUser) {
      const { checkPermission } = useVoixUserAuth()
      allowed = await checkPermission(currentUser, 'Reef.view')
    }

    if (!allowed && to.path !== '/') {
      reefAuthState.value = 'guest'
      return navigateTo('/')
    }

    const isVoixEditor = to.query['voix-sync']

    if (allowed && !isVoixEditor && to.path === '/') {
      reefAuthState.value = 'authenticated'
      return navigateTo('/owners-information')
    }
  }
})
