export default {
  "atlantis-mobile-app": () => import("/home/bun/app/layouts/AtlantisMobileApp.vue"),
  atlantisbahamas: () => import("/home/bun/app/layouts/Atlantisbahamas.vue"),
  barbie: () => import("/home/bun/app/layouts/Barbie.vue"),
  empty: () => import("/home/bun/app/layouts/Empty.vue"),
  "music-making-waves": () => import("/home/bun/app/layouts/MusicMakingWaves.vue"),
  npiwff: () => import("/home/bun/app/layouts/Npiwff.vue"),
  paranza: () => import("/home/bun/app/layouts/Paranza.vue"),
  reef: () => import("/home/bun/app/layouts/Reef.vue"),
  summer: () => import("/home/bun/app/layouts/Summer.vue"),
  summer2024: () => import("/home/bun/app/layouts/Summer2024.vue")
}